@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 37 99 235; /* blue-600 */
    --color-primary-active: 29 78 216; /* blue-700 */
    --color-secondary: 219 234 254; /* blue-100 */
    --color-secondary-active: 191 219 254; /* blue-200 */
  }
}

@layer components {
  .btn {
    @apply flex items-center justify-center;
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
    @apply px-4 py-2 text-sm;

    &.btn-xs {
      @apply px-2.5 py-1.5 text-xs;
    }

    &.btn-sm {
      @apply px-3 py-2 text-sm;
    }

    &.btn-lg {
      @apply px-4 py-2 text-base;
    }

    &.btn-xl {
      @apply px-6 py-3 text-base;
    }

    &.nopadding {
      @apply px-0 py-0;
    }
  }

  .link {
    @apply text-primary hover:text-primary-active;
  }

  .btn-primary {
    @apply border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-primary hover:bg-primary-active focus:ring-primary;
  }

  .btn-secondary {
    @apply border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 bg-secondary hover:bg-secondary-active focus:ring-primary;
  }

  .btn-white {
    @apply border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary border-gray-300;
  }

  .btn-danger {
    @apply border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-red-600 hover:bg-red-700 focus:ring-primary border-gray-300;
  }

  .btn-link {
    @apply text-primary hover:text-primary-active;
  }

  .text-input {
    @apply appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm;
  }

  .text-input-error {
    @apply border-red-300 placeholder-red-300 text-red-900 focus:ring-red-500 focus:border-red-500;
  }

  .checkbox-input {
    @apply focus:ring-primary h-4 w-4 text-blue-600 border-gray-300 rounded;
  }

  .input-label {
    @apply block text-sm text-gray-700;
  }

  .flex-w-3 {
    flex: 0 0 33.333333%;
  }

  .navbar {
    @apply flex w-full h-full items-center px-4;
  }

  .navlink {
    @apply inline-flex items-center px-1 pt-1 text-sm border-b-2 font-medium;
    @apply text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300;

    &.navlink-active {
      @apply text-gray-900 border-primary hover:border-primary-active;
    }
  }

  .navlink-mobile {
    @apply block pl-3 pr-4 py-2 border-l-4 text-base font-medium;
    @apply border-transparent text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300;

    &.navlink-mobile-active {
      @apply border-primary text-blue-700 bg-blue-50 hover:text-primary-active hover:bg-blue-100 hover:border-primary-active;
    }
  }

  .sidebarlink {
    @apply flex items-center px-2 py-2 text-sm font-medium rounded-md;

    &.sidebarlink-active {
      @apply bg-turquoise text-vellum;
    }

    &.sidebarlink-inactive {
      @apply text-vellum hover:bg-turquoise/50;
    }
  }

  .sidebarlink-mobile {
    @apply flex items-center px-2 py-2 text-base font-medium rounded-md;

    &.sidebarlink-mobile-active {
      @apply bg-turquoise text-vellum;
    }

    &.sidebarlink-mobile-inactive {
      @apply text-vellum hover:bg-turquoise/50;
    }
  }

  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }

  /* react-table-sticky */
  .sticky-table {
    overflow: scroll;

    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      @apply border-r;
    }

    [data-sticky-first-right-td] {
      @apply border-l;
    }
  }

  .minerva-search-results {
    em {
      @apply relative text-primary font-medium bg-blue-100;
    }
  }

  .splitview {
    @apply flex lg:flex-row flex-col-reverse;

    .splitview-panel-l,
    .splitview-panel-r {
      @apply overflow-y-scroll h-[calc(100vh-200px)];
    }

    .splitview-panel-l {
      @apply flex-1;
    }

    .splitview-panel-r {
      @apply flex-1;
    }
  }
}

.cornerstone-scroll {
  height: 100%;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;

  .cornerstone-scroll-holder {
    height: calc(100% - 20px);
    margin-top: 5px;
    position: relative;
    width: 12px;

    .cornerstone-scroll-slider {
      height: 12px;
      width: 497px;
      left: 12px;
      padding: 0;
      position: absolute;
      top: 0;
      transform: rotate(90deg);
      transform-origin: top left;
      -webkit-appearance: none;
      background-color: rgba(0, 0, 0, 0);

      &:focus {
        outline: none;
      }
    }
  }
}
